import useRecentSearchCard, {
  SearchProductList
} from '@/hooks/useRecentSearchCard'
import pclnExperimentation from '@pcln/experimentation'
import useBootstrapData from '@/hooks/useBootstrapData'
import useMostRecentFutureBooking from '@/hooks/useMostRecentFutureBooking'
import useExtendStayBooking from '@/hooks/useExtendStayBooking'
import useSeti from '@/hooks/useSeti'
import useAbandonedSelectionsManager from './useAbandonedSelectionManager'

function useTripActivityData(productType: SearchProductList) {
  const {
    webstats: { clientGUID },
    appName,
    appVersion,
    signInStatus: { signedIn }
  } = useBootstrapData()

  const showAbandonedSelectionsAlways =
    useSeti('HP_UPCOMING_TRIP_ABANDONED_SELECTIONS', false) === 'VARIANT'

  const { mostRecentFutureBooking } = useMostRecentFutureBooking()
  const extendStayTrip = useExtendStayBooking()

  const abandonedSelection = useAbandonedSelectionsManager(productType, {
    clientGUID,
    appName,
    appVersion,
    signedIn
  })

  const data = useRecentSearchCard(
    clientGUID,
    { appName, appVersion },
    productType,
    signedIn
  )
  const atMostFiveRecentSearchCards = data.length > 5 ? data.slice(0, 5) : data

  // order of these if blocks matters
  // both extendStayTrip and mostRecentFutureBooking and can be truthy
  // but in that case we only show extendStayTrip related UI
  // For SETI cleanup can just remove both these if blocks and always return {abandonedSelection, atMostFiveRecentSearchCards}
  if (extendStayTrip) {
    if (abandonedSelection || atMostFiveRecentSearchCards.length) {
      pclnExperimentation.fireImpression(
        'HP_UPCOMING_TRIP_ABANDONED_SELECTIONS'
      )
    }
    return showAbandonedSelectionsAlways
      ? {
          abandonedSelection,
          atMostFiveRecentSearchCards
        }
      : {
          abandonedSelection: null,
          atMostFiveRecentSearchCards: []
        }
  }

  if (mostRecentFutureBooking) {
    if (abandonedSelection) {
      pclnExperimentation.fireImpression(
        'HP_UPCOMING_TRIP_ABANDONED_SELECTIONS'
      )
    }
    return {
      abandonedSelection: showAbandonedSelectionsAlways
        ? abandonedSelection
        : null,
      atMostFiveRecentSearchCards
    }
  }

  return {
    abandonedSelection,
    atMostFiveRecentSearchCards
  }
}

export default useTripActivityData

import analytics from '@/shared-utils/analytics'
import getCookieByName from '@/shared-utils/get-cookie-by-name'
import hasGraphErrors from '@/shared-utils/graph-utils'
import { AbandonedSelectionApiResponse } from '@/components/TripActivity/types'
import { type SearchProductList } from '@/hooks/useRecentSearchCard'
import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import { GraphResponse } from '@/types'
import config from 'isomorphic-config'
import { jsonContentTypeHeader } from '../server/constants'

const { url, timeout } = config.client['pcln-graph']

type AbandonedSelectionsQueryResultSignedIn<T> = {
  readonly abandonedSelectionsbyAuthtokenAndCguid: T
}
type AbandonedSelectionsQueryResultSignedOut<T> = {
  readonly abandonedSelectionsbyCguid: T
}

type AbandonedSelectionsGraphResponse =
  | GraphResponse<
      AbandonedSelectionsQueryResultSignedIn<
        ReadonlyArray<AbandonedSelectionApiResponse>
      >
    >
  | GraphResponse<
      AbandonedSelectionsQueryResultSignedOut<
        ReadonlyArray<AbandonedSelectionApiResponse>
      >
    >

function isValidGraphQLResponse(
  payload: unknown
): payload is AbandonedSelectionsGraphResponse {
  if (payload && payload instanceof Object) {
    if ('errors' in payload && Array.isArray(payload.errors)) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'abandonedSelectionsbyAuthtokenAndCguid' in payload.data &&
      (payload.data.abandonedSelectionsbyAuthtokenAndCguid === null ||
        Array.isArray(payload.data.abandonedSelectionsbyAuthtokenAndCguid))
    ) {
      return true
    }
    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'abandonedSelectionsbyCguid' in payload.data &&
      (payload.data.abandonedSelectionsbyCguid === null ||
        Array.isArray(payload.data.abandonedSelectionsbyCguid))
    ) {
      return true
    }
  }
  return false
}

async function fetchAbandonedSelections(
  cguid: string,
  appName: string,
  appVersion: string,
  signedIn: boolean,
  productType: SearchProductList
) {
  const authToken = getCookieByName('dmc') || ''

  const hotelQuery = productType.includes('STAY')
    ? `
    ... on HotelSelection {
      hotelURL: URL
      checkInDate
      checkOutDate
      numberOfAdults
      numberOfRooms
      temp_hotel {
        ... on TEMP_CDP_RtlHotel {
          __typename
          name
          neighborhood
          numberOfReviews
          starRating
          averageRating
          imageURL
        }
        ... on TEMP_CDP_ExpressDealHotel {
          __typename
          description
          location
        }
        ... on TEMP_CDP_PriceBreakers {
          __typename
          description
          location
        }
      }
    }
  `
    : ''

  const carQuery = productType.includes('DRIVE')
    ? `
    ... on RentalCarSelection {
      carURL: URL
      averageRating
      carExample
      carType
      imageURL
      isDropOffAtDifferentLocation
      isOffAirportPickup
      numberOfReviews
      passengerCapacity
      pickupDateTime
      pickupLocation
      retailTypeCarCompanyDetails {
        brandImageURL
      }
      returnDateTime
      returnLocation
    }
  `
    : ''

  const commonOptions = {
    method: 'POST',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(timeout)
      : undefined
  }

  const options = signedIn
    ? {
        ...commonOptions,
        headers: {
          authToken,
          ...jsonContentTypeHeader,
          'apollographql-client-name': appName,
          'apollographql-client-version': appVersion
        },
        body: JSON.stringify({
          query: `
              query AbandonedSelections($authToken: String!, $cguid: String!, $limit: Int) {
                abandonedSelectionsbyAuthtokenAndCguid(authToken: $authToken, cguid: $cguid, limit: $limit) {
                  ${hotelQuery}
                  ${carQuery}
                }
              }
            `,
          variables: {
            authToken,
            cguid,
            limit: 1
          }
        })
      }
    : {
        ...commonOptions,
        headers: {
          ...jsonContentTypeHeader,
          'apollographql-client-name': appName,
          'apollographql-client-version': appVersion
        },
        body: JSON.stringify({
          query: `
              query AbandonedSelections($cguid: String!, $limit: Int) {
                abandonedSelectionsbyCguid(cguid: $cguid, limit: $limit) {
                  ${hotelQuery}
                  ${carQuery}
                }
              }
            `,
          variables: {
            cguid,
            limit: 1
          }
        })
      }

  const requestUrl = signedIn
    ? `${url}?gqlOp=abandonedSelectionsByAuthTokensAndCguid`
    : `${url}?gqlOp=abandonedSelectionsByCguid`

  try {
    const response = await fetch(requestUrl, options)
    const { status, url: urlValue } = response
    if (!response.ok) {
      analytics.logError({
        message:
          'The abandoned selections graph query response status is > 299',
        url: urlValue,
        status,
        appName,
        appVersion
      })
    } else {
      const payload = await response.json()
      if (isValidGraphQLResponse(payload)) {
        if (hasGraphErrors(payload)) {
          const firstErrorMessage = payload.errors?.[0]?.message
          const errorMessage = firstErrorMessage || 'Unknown error'
          const errorObjectAsString = JSON.stringify(payload.errors)
          const message =
            'The abandoned selections graph query response has returned errors.'
          analytics.logError({
            message,
            errorMessage,
            url: requestUrl,
            cguid,
            appName,
            appVersion,
            errorObjectAsString
          })
        } else {
          const abandonedSelectionsPayload =
            'abandonedSelectionsbyCguid' in payload.data
              ? payload.data.abandonedSelectionsbyCguid
              : payload.data.abandonedSelectionsbyAuthtokenAndCguid

          const logTripActivityDataInfo = {
            hasItems: abandonedSelectionsPayload.length > 0,
            retailHotel: false,
            expressDealHotel: false,
            priceBreakersHotel: false,
            rentalCar: false
          }

          abandonedSelectionsPayload.forEach(selection => {
            if ('hotelURL' in selection) {
              const { __typename } = selection.temp_hotel

              if (__typename === 'TEMP_CDP_RtlHotel') {
                logTripActivityDataInfo.retailHotel = true
              } else if (__typename === 'TEMP_CDP_ExpressDealHotel') {
                logTripActivityDataInfo.expressDealHotel = true
              } else if (__typename === 'TEMP_CDP_PriceBreakers') {
                logTripActivityDataInfo.priceBreakersHotel = true
              }
            }

            if ('carURL' in selection) {
              logTripActivityDataInfo.rentalCar = true
            }
          })

          analytics.log({
            message: 'trip activity successful response',
            ...logTripActivityDataInfo
          })

          return abandonedSelectionsPayload
        }
      } else {
        analytics.logError({
          message: `The abandoned selections graph query response is not as per contract`,
          payload: JSON.stringify(payload)
        })
      }
    }
  } catch (error) {
    const errorMessage =
      error && error instanceof Error
        ? `${error.message} Error Name: ${error.name}`
        : 'Unknown error'
    analytics.logError({
      message: 'Failed to call the abandoned selections graph query.',
      errorMessage,
      error: JSON.stringify(error)
    })
  }
  return []
}

export default fetchAbandonedSelections

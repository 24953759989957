import React, { useContext } from 'react'
import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  getPaletteColor
} from 'pcln-design-system'
import styled from 'styled-components'
import RotatingBanner from '@/components/RotatingBanner'
import useExpressDealDialog from '@/hooks/useExpressDealDialog'
import { SearchFormContext } from '@/context/SearchFormContext'
import {
  formatShopSearchLink,
  handleHotelsBumperClick
} from '@/components/Hotels/SearchForm/utils'
import useBootstrapData from '@/hooks/useBootstrapData'
import ExpressDealDialog from '../Flights/ExpressDealDialog'

const imageFolder =
  'https://s1.pclncdn.com/design-assets/next-landing/assets/images/bumper/'

const Wrapper = styled(Box).attrs(() => ({
  p: 3
}))`
  border-top: 1px solid ${getPaletteColor('border.base')};
`

const StyledButton = styled(Button)`
  &:focus {
    outline: solid ${getPaletteColor('primary.base')} 1px;
    outline-offset: 2px;
  }
`

export function FlightsBumper() {
  const { isOpen, openDialog, closeDialog } = useExpressDealDialog()
  const { isMobile } = useBootstrapData()
  return (
    <Wrapper>
      <StyledButton
        color="transparent"
        onClick={openDialog}
        p={0}
        width={1}
        aria-label="Save up to 50% by searching Express Deals."
        data-testid="flight-express-deal-bumper"
      >
        <RotatingBanner
          altText="Partner Airline Logos"
          height="25px"
          width="280.2px"
          image={`${imageFolder}flights_logo_bumper.jpg?opto&auto=avif,webp`}
          primaryBannerText="Save up to 50% by searching Express Deals."
          secondaryBannerText="Trusted airlines, incredible deals."
        />
      </StyledButton>
      <ExpressDealDialog
        isMobile={isMobile}
        isOpen={isOpen}
        closeDialog={closeDialog}
      />
    </Wrapper>
  )
}

export function CarsBumper() {
  return (
    <Wrapper>
      <RotatingBanner
        altText="Partner Car Rental Company Logos"
        height="25px"
        width="310px"
        image={`${imageFolder}rcBumper.png?opto&auto=avif,webp`}
        primaryBannerText="Save up to 40% by searching Express Deals."
        secondaryBannerText="Trusted brands, incredible deals."
      />
    </Wrapper>
  )
}

export function VacationBumper() {
  return (
    <Wrapper>
      <Text fontSize={1} textAlign="center" color="text.light">
        Save up to $625 when you book your hotel and flight together!
      </Text>
    </Wrapper>
  )
}

const TextSpan = Text.span

export function CruiseBumper() {
  return (
    <Wrapper>
      <Text fontSize={1} textAlign="center" color="text.light">
        Get up to $1,000 to spend at sea when you&nbsp;
        <TextSpan fontWeight="bold" color="primary">
          <Link
            href="https://cruises.priceline.com?utm_medium=partner_site_banner&utm_source=pcln-partner_site&utm_campaign=20190307&utm_content=widget_link"
            target="_blank"
          >
            book a cruise with Priceline.
          </Link>
        </TextSpan>
      </Text>
    </Wrapper>
  )
}

export function HotelsBumper({ showMultiHotel }: { showMultiHotel?: boolean }) {
  const { formValues } = useContext(SearchFormContext)
  if (showMultiHotel) {
    return null
  }

  return (
    <Wrapper>
      <Text fontSize={1}>
        <Flex flexWrap="wrap" justifyContent="center">
          <Link
            href={formatShopSearchLink(formValues)}
            onClick={handleHotelsBumperClick}
            target="_blank"
          >
            Book all of your hotels at once and save up to $625
          </Link>
        </Flex>
      </Text>
    </Wrapper>
  )
}

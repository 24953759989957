import React, { useEffect } from 'react'
import {
  TripActivity as RecentActivityHotel,
  RecentActivityRentalCar
} from '@pcln/marketing'
import {
  ANALYTICS_CATEGORY_HOME,
  ANALYTICS_CATEGORY_HOTELS
} from '@/constants/analytics'
import {
  fireAbandonedSelectionClickEvent,
  fireAbandonedSelectionLoadEvent
} from './GA4Helpers'
import {
  type AbandonedSelectionExperiment,
  type AbandonedSelection
} from './types'

export default function AbandonedSelection({
  abandonedSelection,
  analyticsCategory,
  isLandingPage
}: {
  abandonedSelection: AbandonedSelection | AbandonedSelectionExperiment
  analyticsCategory:
    | typeof ANALYTICS_CATEGORY_HOME
    | typeof ANALYTICS_CATEGORY_HOTELS
  isLandingPage: boolean
}) {
  useEffect(() => {
    if (abandonedSelection) {
      fireAbandonedSelectionLoadEvent(
        analyticsCategory,
        abandonedSelection,
        isLandingPage
      )
    }
  }, [abandonedSelection, analyticsCategory, isLandingPage])

  if (abandonedSelection.productType === 'cars') {
    return (
      <RecentActivityRentalCar
        {...abandonedSelection}
        subheadline={
          <>
            Pick up Location: {abandonedSelection.pickupLocation}
            {abandonedSelection.isDropOffAtDifferentLocation && (
              <>
                <br />
                Drop Off Location: {abandonedSelection.returnLocation}
              </>
            )}
          </>
        }
        onClick={() => {
          fireAbandonedSelectionClickEvent(
            analyticsCategory,
            abandonedSelection,
            isLandingPage
          )
        }}
        altText="Trip activity image"
        ariaLabel="trip activity button"
      />
    )
  }

  return (
    <RecentActivityHotel
      {...abandonedSelection}
      onClick={() => {
        fireAbandonedSelectionClickEvent(
          analyticsCategory,
          abandonedSelection,
          isLandingPage
        )
      }}
      altText="Trip activity image"
      ariaLabel="trip activity button"
    />
  )
}

import { useState, useEffect } from 'react'
import {
  AbandonedSelectionApiResponse,
  AbandonedSelectionApiResponseExperiment
} from '@/components/TripActivity/types'
import { type SearchProductList } from '@/hooks/useRecentSearchCard'
import fetchAbandonedSelectionsExperiment from '@/shared-utils/fetchAbandonedSelectionsExperiment'
import fetchAbandonedSelections from '@/shared-utils//fetchAbandonedSelections'

export default function useAbandonedSelections(
  cguid: string,
  appName: string,
  appVersion: string,
  signedIn: boolean,
  productType: SearchProductList,
  isVariant?: boolean
) {
  const [abandonedSelections, setAbandonedSelections] = useState<
    | ReadonlyArray<AbandonedSelectionApiResponseExperiment>
    | ReadonlyArray<AbandonedSelectionApiResponse>
    | null
  >(null)

  useEffect(() => {
    async function fetchAbandonedItems() {
      if (!abandonedSelections) {
        if (isVariant) {
          const response = await fetchAbandonedSelectionsExperiment(
            cguid,
            appName,
            appVersion,
            signedIn,
            productType
          )
          setAbandonedSelections(response)
        } else {
          const response = await fetchAbandonedSelections(
            cguid,
            appName,
            appVersion,
            signedIn,
            productType
          )
          setAbandonedSelections(response)
        }
      }
    }

    void fetchAbandonedItems()
  }, [
    signedIn,
    productType,
    cguid,
    isVariant,
    appName,
    appVersion,
    abandonedSelections
  ])
  return abandonedSelections || []
}

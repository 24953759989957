import { SearchProductList } from '@/hooks/useRecentSearchCard'
import useSeti from '@/hooks/useSeti'
import { parse, isToday, isAfter } from 'date-fns'
import useAbandonedSelections from './useAbandonedSelections'
import {
  AbandonedCarSelectionApiResponse,
  AbandonedHotelSelectionApiResponse,
  AbandonedHotelSelectionApiResponseExperiment,
  AbandonedSelectionApiResponse,
  AbandonedSelectionApiResponseExperiment,
  BootstrapData
} from '../types'
import {
  transformAbandonedHotelApiResponseToProps,
  transformAbandonedCarApiResponseToProps,
  transformAbandonedHotelApiResponseToPropsExperiment,
  transformAbandonedCarApiResponseToPropsExperiment
} from '../utils'

function isTripDateValid(tripDate: string, formatString: string) {
  const parseTripDate = parse(tripDate, formatString, new Date())

  return isToday(parseTripDate) || isAfter(parseTripDate, new Date())
}

function isValidAbandonedHotelSelectionExperiment(
  selection: AbandonedSelectionApiResponseExperiment
): selection is AbandonedHotelSelectionApiResponseExperiment {
  return (
    'hotelURL' in selection &&
    isTripDateValid(selection.checkInDate, 'yyyy-MM-dd')
  )
}

function isValidAbandonedCarSelectionExperiment(
  selection: AbandonedSelectionApiResponse
): selection is AbandonedCarSelectionApiResponse {
  return (
    'carURL' in selection &&
    isTripDateValid(selection.pickupDateTime, "yyyy-MM-dd'T'HH:mm:ss")
  )
}

function isValidAbandonedHotelSelection(
  selection: AbandonedSelectionApiResponse
): selection is AbandonedHotelSelectionApiResponse {
  return (
    'hotelURL' in selection &&
    isTripDateValid(selection.checkInDate, 'EEE, MMM d, yyyy')
  )
}

function isValidAbandonedCarSelection(
  selection: AbandonedSelectionApiResponse
): selection is AbandonedCarSelectionApiResponse {
  return (
    'carURL' in selection &&
    isTripDateValid(selection.pickupDateTime, "EEE, MMM dd, yyyy 'at' h:mmaaa")
  )
}

function isValidAbandonedSelectionApiResponse(
  selections: ReadonlyArray<unknown>,
  isVariant: boolean
): selections is ReadonlyArray<AbandonedSelectionApiResponse> {
  return !isVariant && selections?.length > 0
}

function isValidAbandonedSelectionApiResponseExperiment(
  selections: ReadonlyArray<unknown>,
  isVariant: boolean
): selections is ReadonlyArray<AbandonedHotelSelectionApiResponseExperiment> {
  return isVariant && selections?.length > 0
}

export default function useAbandonedSelectionsManager(
  productType: SearchProductList,
  bootstrapData: BootstrapData
) {
  const { clientGUID, appName, appVersion, signedIn } = bootstrapData

  const showAbandonedSelectionsExperiment =
    useSeti('HP_ABANDONED_TRIPS_TRIP_ACTIVITY_QL', true) === 'VARIANT'

  const abandonedSelectionsResponse = useAbandonedSelections(
    clientGUID,
    appName,
    appVersion,
    signedIn,
    productType,
    showAbandonedSelectionsExperiment
  )

  if (
    isValidAbandonedSelectionApiResponse(
      abandonedSelectionsResponse,
      showAbandonedSelectionsExperiment
    )
  ) {
    const abandonedSelection =
      abandonedSelectionsResponse.find(selection => {
        if (isValidAbandonedHotelSelection(selection)) {
          return true
        }

        return isValidAbandonedCarSelection(selection)
      }) ?? null

    if (abandonedSelection) {
      return 'hotelURL' in abandonedSelection
        ? transformAbandonedHotelApiResponseToProps(abandonedSelection)
        : transformAbandonedCarApiResponseToProps(abandonedSelection)
    }
  }

  if (
    isValidAbandonedSelectionApiResponseExperiment(
      abandonedSelectionsResponse,
      showAbandonedSelectionsExperiment
    )
  ) {
    const abandonedSelection =
      abandonedSelectionsResponse.find(selection => {
        if (isValidAbandonedHotelSelectionExperiment(selection)) {
          return true
        }

        return isValidAbandonedCarSelectionExperiment(selection)
      }) ?? null

    if (abandonedSelection) {
      return 'hotelURL' in abandonedSelection
        ? transformAbandonedHotelApiResponseToPropsExperiment(
            abandonedSelection
          )
        : transformAbandonedCarApiResponseToPropsExperiment(abandonedSelection)
    }
  }

  return null
}

import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Box, Text, Button, Flex, Heading, theme } from 'pcln-design-system'
import { Carousel } from 'pcln-carousel'
import { ActionCard } from '@pcln/cards'
import AbandonedSelection from '@/components/TripActivity/AbandonedSelection'
import {
  ANALYTICS_CATEGORY_HOME,
  ANALYTICS_CATEGORY_HOTELS
} from '@/constants/analytics'
import useDetectBrowser from '@/hooks/useDetectBrowser'
import useSeti from '@/hooks/useSeti'
import { type SearchProductList } from '@/hooks/useRecentSearchCard'
import getProductInfo, { getRecentSearchDisplayProps } from './utils'
import useTripActivityData from './hooks/useTripActivityData'
import StyledWrapper from './TripActivity.styled'
import { RecentTripSearches, SearchProductDataFiltered } from './types'
import fireTripActivityCardClickEvent, {
  fireTripActivityLoadEvent,
  fireViewAllButtonClickEvent
} from './GA4Helpers'

function onCardClick(
  relativeUrl: string,
  analyticsCategory: string,
  item: SearchProductDataFiltered | RecentTripSearches
) {
  fireTripActivityCardClickEvent(item.productType, analyticsCategory)
  const url = new URL(relativeUrl, window.location.origin)
  window.open(url)
}

function onViewAllButtonClick(analyticsCategory: string) {
  fireViewAllButtonClickEvent(analyticsCategory)
  window.open('/next-profile/trips?tab=recent_activity')
}

export default function TripActivity({
  isLandingPage = false,
  productType,
  analyticsCategory,
  setIsVisible,
  shouldUseSmallerHeading
}: {
  isLandingPage?: boolean
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  productType: SearchProductList
  analyticsCategory:
    | typeof ANALYTICS_CATEGORY_HOME
    | typeof ANALYTICS_CATEGORY_HOTELS
  shouldUseSmallerHeading?: boolean
}) {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg as string})`
  })
  const isBrowser = useDetectBrowser()
  const isNewRecentSearchQuery = useSeti('HP_RT_SEARCHES', false) === 'VARIANT'
  const { atMostFiveRecentSearchCards, abandonedSelection } =
    useTripActivityData(productType)
  const hasRecentSearchCards = atMostFiveRecentSearchCards.length > 0
  useEffect(() => {
    if (setIsVisible) {
      setIsVisible(hasRecentSearchCards || abandonedSelection !== null)
    }
  }, [hasRecentSearchCards, abandonedSelection, setIsVisible])

  useEffect(() => {
    if (atMostFiveRecentSearchCards.length > 0) {
      fireTripActivityLoadEvent(
        analyticsCategory,
        atMostFiveRecentSearchCards,
        isLandingPage
      )
    }
  }, [atMostFiveRecentSearchCards, analyticsCategory, isLandingPage])

  return hasRecentSearchCards || abandonedSelection ? (
    <StyledWrapper borderRadius="2xl" bg="priceSecondary.light" mb="30px">
      <Box>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Heading
            mt={0}
            mb={3}
            ml={[1, null, null, 0]}
            textStyle={
              shouldUseSmallerHeading
                ? ['heading4', 'heading3']
                : ['heading3', 'heading2']
            }
            color="text.heading"
          >
            Pick up where you left off
          </Heading>
          {isDesktop && (
            <Button
              variation="white"
              data-testid="view-all-button-top-right"
              onClick={() => onViewAllButtonClick(analyticsCategory)}
              {...(shouldUseSmallerHeading && { size: 'small' })}
            >
              View All Recent Activity
            </Button>
          )}
        </Flex>
        {abandonedSelection && (
          <>
            <Box mb={2}>
              <AbandonedSelection
                isLandingPage={isLandingPage}
                abandonedSelection={abandonedSelection}
                analyticsCategory={analyticsCategory}
              />
            </Box>
            {hasRecentSearchCards && (
              <Text
                textStyle="heading4"
                color="text.heading"
                pt={3}
                pb={[2, null, 3]}
              >
                Or continue browsing
              </Text>
            )}
          </>
        )}
        {isBrowser && hasRecentSearchCards && (
          <Carousel
            arrowsPositions="top"
            arrowsPosition="side"
            currentSlide={0}
            displayArrowsMobile={false}
            slideSpacing={1}
            onSlideChange={() => {
              // do  nothing
            }}
            visibleSlides={[2.5, 2.5, 3.5]}
            mobileVisibleSlides={[1.2, 2.5]}
          >
            {atMostFiveRecentSearchCards.map((item, index) => {
              const { overline, heading, icon, actionText, relativeUrl } =
                isNewRecentSearchQuery
                  ? getRecentSearchDisplayProps(item as RecentTripSearches)
                  : getProductInfo(item as SearchProductDataFiltered)
              return (
                <ActionCard
                  key={overline || index}
                  headingWidth={['224px', null, null, '250px']}
                  icon={icon}
                  heading={heading}
                  actionText={actionText}
                  overline={overline}
                  onClick={() => {
                    onCardClick(relativeUrl, analyticsCategory, item)
                  }}
                  handleSubmitCTA={() => {
                    onCardClick(relativeUrl, analyticsCategory, item)
                  }}
                  className="full-width"
                />
              )
            })}
          </Carousel>
        )}

        {!isDesktop && (
          <Box pt={24}>
            <Button
              variation="white"
              width={1}
              data-testid="view-all-button-bottom"
              onClick={() => onViewAllButtonClick(analyticsCategory)}
              {...(shouldUseSmallerHeading && { size: 'small' })}
            >
              View All Recent Activity
            </Button>
          </Box>
        )}
      </Box>
    </StyledWrapper>
  ) : null
}
